import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PostsService } from 'src/app/services/posts.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.sass']
})
export class PostsComponent implements OnInit {

  postList: any;
  error = false;
  pinnedPosts: any[] = [];

  constructor(private title: Title, private postService: PostsService) { }

  ngOnInit() {
    this.title.setTitle('News | M. S. Alam');
    this.postService.getPosts().subscribe((data) => {
      this.postList = data;

      this.postList.sort((a, b) => {
          return b['postdate'].localeCompare(a['postdate']);
      });

      this.postList.forEach((post: Object) => {
          if (post['pinned']) {
              this.pinnedPosts.push(post);
          }
      });
    }, (err) => {
      alert('Cannot Load Posts!!');
      this.error = true;
    });
  }
}
