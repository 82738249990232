import { Routes } from '@angular/router';

import { ManagePostsComponent } from './manage-posts/manage-posts.component';

export const AdminRoutes: Routes = [
    {
        path: 'dashboard',
        redirectTo: 'manageposts',
        pathMatch: 'full'
    },
    {
        path: 'manageposts',
        component: ManagePostsComponent,
        pathMatch: 'full'
    }
];
