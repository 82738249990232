import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PostsService {

  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.api + 'api/posts/';
  }

  getPosts = () => {
    return this.http.get(this.apiUrl, {withCredentials: true});
  }

  makePosts = (data: any) => {
      console.log(data)
    return this.http.post(this.apiUrl + 'new', data, {withCredentials: true});
  }

  deletePosts = (data: any) => {
    return this.http.post(this.apiUrl + 'delete', data, {withCredentials: true});
  }

  savePinnedPosts = (data: any) => {
    return this.http.post(this.apiUrl + 'pin', data, {withCredentials: true});
  }
}
