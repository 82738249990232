import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { MatToolbarModule,
    MatMenuModule
} from '@angular/material';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PostEntityComponent } from './post-entity/post-entity.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        PostEntityComponent
    ],
    imports: [
        MatToolbarModule,
        MatMenuModule,
        RouterModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        PostEntityComponent
    ],
    providers: []
})

export class SharedModule { }
