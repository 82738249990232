import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { FormBuilder, Validators } from "@angular/forms";
import { PostsService } from "src/app/services/posts.service";

@Component({
  selector: "app-manage-posts",
  templateUrl: "./manage-posts.component.html",
  styleUrls: ["./manage-posts.component.sass"],
})
export class ManagePostsComponent implements OnInit {
  postForm;
  postList: any;
  pinnedPosts: any;
  showPinSaveCancelButtons: Boolean = false;
  error: any = null;

  constructor(
    private title: Title,
    private formbuilder: FormBuilder,
    private postService: PostsService,
    private router: Router
  ) {
    this.title.setTitle("Manage Posts | M. S. Alam");
    this.postForm = formbuilder.group({
      heading: ["", Validators.required],
      postdate: ["", Validators.required],
      desc: [""],
      link: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.postService.getPosts().subscribe(
      (data) => {
        this.postList = data;
        this.clonePostsToPinnedPosts();
      },
      (err) => {
        this.error = "Cannot load Posts!!";
        alert("Cannot load Posts!!");
      }
    );
  }

  clonePostsToPinnedPosts() {
    this.pinnedPosts = [];
    this.postList.forEach((aPost) => {
      this.pinnedPosts.push(JSON.parse(JSON.stringify(aPost)));
    });
  }

  onSubmit() {
    if (
      this.postForm.get("heading").value &&
      this.postForm.get("link").value &&
      this.postForm.get("postdate").value
    ) {
      this.postService.makePosts(this.postForm.value).subscribe((data) => {
        if (data["status"] === 200) {
          alert("Post has been saved successfully");
          this.postList.push(data["data"]);
          this.pinnedPosts.push(data["data"]);
          this.postForm.reset();
          this.router.navigate(["/admin", "dashboard"]);
        } else if (data["status"] === 221) {
          alert(data["message"]);
          this.router.navigate(["/admin", "login"]);
        } else {
          alert(data["message"]);
        }
      });
    } else {
      alert("Heading or Date or Link is missing");
    }
  }

  onDelete(event) {
    const postId =
      event.target.parentNode.previousSibling.firstChild.firstChild.attributes
        .id.nodeValue;
    this.postService.deletePosts({ _id: postId }).subscribe((data) => {
      if (data["status"] === 200) {
        alert("Post Deleted Successfully");
        for (let ind = 0; ind < this.postList.length; ind++) {
          if (this.postList[ind]._id === postId) {
            break;
          }
        }
      } else if (data["status"] === 221) {
        alert(data["message"]);
        this.router.navigate(["/admin", "login"]);
      } else {
        alert(data["message"]);
      }
    });
  }

  togglePin(event) {
    this.showPinSaveCancelButtons = true;
    const postId =
      event.target.parentNode.previousSibling.firstChild.firstChild.attributes
        .id.nodeValue;
    const post = this.pinnedPosts.find((aPost) => aPost._id === postId);
    if (post) {
      post["pinned"] = !post["pinned"];
    } else {
      alert("Invalid Operation");
    }
  }

  savePins() {
    const allPinedPostIds = [];
    this.pinnedPosts.forEach((aPost) => {
      if (aPost["pinned"] === true) {
        allPinedPostIds.push(aPost._id);
      }
    });
    this.postService.savePinnedPosts({pins: allPinedPostIds}).subscribe((data) => {
      if (data["status"] === 200) {
        alert("Pins have been saved successfully");
        this.router.navigate(["/admin", "dashboard"]);
        this.ngOnInit();
        this.showPinSaveCancelButtons = false;
      } else if (data["status"] === 221) {
        alert(data["message"]);
        this.router.navigate(["/admin", "login"]);
      } else {
        alert(data["message"]);
      }
    });
  }

  cancelPins() {
    this.showPinSaveCancelButtons = false;
    this.clonePostsToPinnedPosts();
  }
}
