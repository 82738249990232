import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule,
    MatMenuModule,
    MatTab,
    MatTabsModule
} from '@angular/material';


import { AdminRoutes } from './admin.routing';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ManagePostsComponent } from './manage-posts/manage-posts.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
    declarations: [
        AdminDashboardComponent,
        ManagePostsComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatMenuModule,
        ReactiveFormsModule,
        MatTabsModule,
        SharedModule,
        RouterModule.forChild(AdminRoutes)
    ],
    exports: [
    ],
    providers: []
})

export class AdminModule { }
