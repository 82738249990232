import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-post-entity',
  templateUrl: './post-entity.component.html',
  styleUrls: ['./post-entity.component.sass']
})
export class PostEntityComponent implements OnInit {

  @Input()
  postEntity: any;

  constructor() { }

  ngOnInit() {
  }

}
