import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private authUrl: string;

  constructor(private http: HttpClient) {
    this.authUrl = environment.api + 'api/auth/';
  }

  login = (obj: any) => {
    const loginUrl = this.authUrl + 'login';
    return this.http.post(loginUrl, obj, {withCredentials: true});
  }

  logout = (obj: any) => {
    const logoutUrl = this.authUrl + 'logout';
    return this.http.post(logoutUrl, obj, {withCredentials: true});
  }

}
