import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {

  loginForm;

  constructor(private title: Title, private formbuilder: FormBuilder, private authservice: AuthService, private router: Router) {
    this.loginForm = this.formbuilder.group({
      username: ['msalam', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.title.setTitle('Login | M. S. Alam');
  }

  onSubmit() {
    if (this.loginForm.get('username').value && this.loginForm.get('password').value) {
      this.authservice.login(this.loginForm.value).subscribe((data) => {
        if (data['status'] === 200) {
          this.router.navigate(['/admin', 'dashboard']);
        } else if (data['status'] === 221) {
          this.router.navigate(['/admin', 'dashboard']);
        } else {
          alert(data['message']);
        }
      });
    } else {
      alert('Username or Password is missing');
    }
  }
}
